import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import classname from 'classnames';

// i18n
import { withTranslation } from 'react-i18next';

import { connect, useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import withRouter from '../../../../../Helpers/WithRouter';
import { ROUTE_GAMES_PAGE, ROUTE_USERS_PAGE } from '../../../../../Constants/urls';
import { PERMISSIONS } from '../../../../../../Containers/Auth/Constants/authorization';

function Navbar({
  router, leftMenu, t, menuOpen,
}) {
  const [dashboard, setdashboard] = useState(false);
  const [ui, setui] = useState(false);
  const [app, setapp] = useState(false);

  const { profile } = useSelector((state) => state.Auth);

  function activateParentDropdown(item) {
    item.classList.add('active');
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add('active'); // li
      const parent2 = parent.parentElement;
      parent2.classList.add('active'); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add('active'); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add('active'); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add('active'); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add('active'); // li
            }
          }
        }
      }
    }
    return false;
  }

  useEffect(() => {
    let matchingMenuItem = null;
    const ul = document.getElementById('navigation');
    const items = ul.getElementsByTagName('a');
    for (let i = 0; i < items.length; i += 1) {
      if (router.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });

  return (
    <div className="topnav">
      <div style={{ maxWidth: '85%', marginLeft: 'auto', marginRight: 'auto' }}>
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={(e) => {
                      e.preventDefault();
                      setdashboard(!dashboard);
                    }}
                    to="/dashboard"
                  >
                    <i className="bx bx-home-circle me-2" />
                    {t('Dashboard')}
                    {' '}
                    {menuOpen}
                    <div className="arrow-down" />
                  </Link>
                  <div
                    className={classname('dropdown-menu', { show: dashboard })}
                  >
                    <Link to="/dashboard" className="dropdown-item">
                      {t('Default')}
                    </Link>
                    <Link to="/#" className="dropdown-item">
                      {t('Saas')}
                    </Link>
                    <Link to="/#" className="dropdown-item">
                      {t('Crypto')}
                    </Link>
                    <Link to="/#" className="dropdown-item">
                      {t('Blog')}
                    </Link>
                    <Link to="/#" className="dropdown-item">
                      {t('Jobs')}
                    </Link>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      setui(!ui);
                    }}
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="bx bx-tone me-2" />
                    {t('Games management')}
                    <div className="arrow-down" />
                  </Link>
                  <div
                    className={classname(
                      'dropdown-menu',
                      { show: ui },
                    )}
                  >
                    <div>
                      <Link to={ROUTE_GAMES_PAGE} className="dropdown-item">
                        {t('Games')}
                      </Link>
                    </div>
                  </div>
                </li>
                {!_isEmpty(profile) && profile.hasRight(PERMISSIONS.USER_FULL_OTHER_READ) && (

                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        setapp(!app);
                      }}
                      className="nav-link dropdown-togglez arrow-none"
                    >
                      <i className="bx bx-customize me-2" />
                      {t('Administration')}
                      <div className="arrow-down" />
                    </Link>
                    <div className={classname('dropdown-menu', { show: app })}>
                      <Link to={ROUTE_USERS_PAGE} className="dropdown-item">
                        {t('Users')}
                      </Link>
                    </div>
                  </li>
                )}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </div>
  );
}

Navbar.propTypes = {
  leftMenu: PropTypes.node.isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  menuOpen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,

};

const mapStatetoProps = () => {
  const { leftMenu } = { leftMenu: false };
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar)),
);
