import React, { lazy } from 'react';

const LoginPage = lazy(() => import('../Components/Pages/LoginPage'));
const RegistrationPage = lazy(() => import('../Components/Pages/RegistrationPage'));

const authRoutes = [
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/registration',
    element: <RegistrationPage />,
  },
];
export default authRoutes;
