import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// redux
import { useSelector, useDispatch } from 'react-redux';

// components
import Navbar from './Components/Navbar';
import Header from './Components/Header';
import Footer from './Components/Footer';
import withRouter from '../../../../Helpers/WithRouter';
import RightSidebar from './Components/RightSidebar';

import {
  changeLayout,
  changeTopbarTheme,
  changeLayoutWidth,
} from './Store/layout/actions';

function Layout({ router, children }) {
  const dispatch = useDispatch();

  const {
    topbarTheme, layoutWidth, showRightSidebar,
  } = useSelector(() => ({
    topbarTheme: 'dark',
    layoutWidth: 'scrollable',
    showRightSidebar: false,
  }));

  /*
  document title
  */
  useEffect(() => {
    const title = router.location.pathname;
    const currentage = title.charAt(1).toUpperCase() + title.slice(2);

    document.title = `${currentage} | Skote - React Admin & Dashboard Template`;
  }, [router.location.pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /*
  layout settings
  */
  useEffect(() => {
    dispatch(changeLayout('horizontal'));
  }, [dispatch]);

  useEffect(() => {
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme));
    }
  }, [dispatch, topbarTheme]);

  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth));
    }
  }, [dispatch, layoutWidth]);

  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  return (
    <>
      <div id="layout-wrapper">
        <Header
          theme={topbarTheme}
          isMenuOpened={isMenuOpened}
          openLeftMenuCallBack={openMenu}
        />
        <Navbar menuOpen={isMenuOpened} />
        <div className="main-content" style={{ paddingTop: '50px', margin: 'auto', maxWidth: '85%' }}>
          {children}
        </div>
        <Footer />
      </div>

      {showRightSidebar ? <RightSidebar /> : null}
    </>
  );
}

Layout.propTypes = {
  router: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default withRouter(Layout);
