import axios from 'axios';
import { API_BASE_URL, API_CSRF_TOKEN_RECEIVE_URL } from '../../Constants/configs';
import { receivedRequestEvent, sendRequestEvent } from '../../Store/Actions/actions';
import notify from '../../Helpers/Notification';

export default class ApiSenderFacade {
  static instance = null;

  defaultHeaders = {
    'Content-Type': 'application/json',
  };

  axiosCopy = null;

  dispatchCopy = null;

  constructor(dispatch) {
    this.dispatchCopy = dispatch;

    const axiosApi = axios.create({
      baseURL: API_BASE_URL,
    });

    axiosApi.interceptors.response.use(
      (response) => response,
      (error) => Promise.reject(error),
    );

    axiosApi.defaults.withCredentials = true;

    this.axiosCopy = axiosApi;
  }

  static getInstance(dispatch = null) {
    if (this.instance === null) {
      this.instance = new ApiSenderFacade(dispatch);
    }

    return this.instance;
  }

  prepareRequest(config) {
    return {
      ...config,
      headers: {
        ...this.defaultHeaders,
        ...config.headers,
      },
    };
  }

  // eslint-disable-next-line class-methods-use-this
  processErrorResponse(e) {
    if (e.response) {
      if (e.response.status >= 400
        && e.response.status < 500
        && e.response.status !== 419
        && e.response.status !== 401
      ) {
        notify({
          type: 'error',
          title: 'Error:',
          message: e.response.data.message,
        });
      } else if (e.response.status >= 500) {
        notify({
          type: 'error',
          title: 'Server error',
          message: 'We notified development team about the issue',
        });
      } else if (e.response.status === 401) {
        notify({
          type: 'error',
          title: 'Authentication error',
          message: 'Please make sure that credentials you are using are correct or re-login',
        });
      }
    } else {
      notify({
        type: 'error',
        title: 'Server error',
        message: 'We notified development team about the issue',
      });
    }

    throw e;
  }

  async post(url, data, providedConfig = {}) {
    const config = this.prepareRequest(providedConfig);

    const savedRequest = () => this.axiosCopy
      .post(url, data, config)
      .catch((e) => (config
        ?.hasOwnProperty('onError') ? config.onError(e) : this.processErrorResponse(e)));

    return this.processRequest(savedRequest);
  }

  async get(url, providedConfig = {}) {
    const config = this.prepareRequest(providedConfig);

    this.dispatchCopy(sendRequestEvent());

    return this.axiosCopy
      .get(url, { ...config })
      .then((response) => {
        this.dispatchCopy(receivedRequestEvent());

        return response.data;
      })
      .catch((e) => (config
        ?.hasOwnProperty('onError') ? config.onError(e) : this.processErrorResponse(e)));
  }

  async put(url, data, providedConfig = {}) {
    const config = this.prepareRequest(providedConfig);

    const savedRequest = () => this.axiosCopy
      .put(url, data, config)
      .catch((e) => (config
        ?.hasOwnProperty('onError') ? config.onError(e) : this.processErrorResponse(e)));

    return this.processRequest(savedRequest);
  }

  async patch(url, data, providedConfig = {}) {
    const config = this.prepareRequest(providedConfig);

    const savedRequest = () => this.axiosCopy
      .patch(url, data, config)
      .catch((e) => (config
        ?.hasOwnProperty('onError') ? config.onError(e) : this.processErrorResponse(e)));

    return this.processRequest(savedRequest);
  }

  async del(url, providedConfig = {}) {
    const config = this.prepareRequest(providedConfig);

    const savedRequest = () => this.axiosCopy
      .delete(url, config)
      .catch((e) => (config
        ?.hasOwnProperty('onError') ? config.onError(e) : this.processErrorResponse(e)));

    return this.processRequest(savedRequest);
  }

  async handleCSRFMismatch(error, apiRequest) {
    if (
      error.response?.status === 419
      && error.response?.data.message === 'CSRF token mismatch.'
    ) {
      notify({
        type: 'success',
        title: 'Message:',
        message: 'Don\'t worry, your request in process, just wait a few seconds please',
      });

      this.axiosCopy
        .get(API_CSRF_TOKEN_RECEIVE_URL)
        .then(() => {
          this.dispatchCopy(sendRequestEvent());

          apiRequest();
          // todo(mt): add running of promises till they exist
        })
        .catch(() => this.dispatchCopy(receivedRequestEvent()));
    }

    this.dispatchCopy(receivedRequestEvent());
  }

  async processRequest(request) {
    this.dispatchCopy(sendRequestEvent());

    return request()
      .then((response) => {
        this.dispatchCopy(receivedRequestEvent());

        return response.data;
      })
      .catch(
        (error) => this.handleCSRFMismatch(
          error,
          () => request()
            .then(
              (response) => {
                this.dispatchCopy(receivedRequestEvent());

                return response.data;
              },
            )
            .catch(
              () => {
                this.dispatchCopy(receivedRequestEvent());

                notify({
                  type: 'error',
                  title: 'Error:',
                  message: 'We notified development team about the issue',
                });
              },
            ),
        ),
      );
  }
}
