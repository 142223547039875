import React from 'react';
import { Container, Row, Col } from 'reactstrap';

function Footer() {
  return (
    <footer className="footer" style={{ left: 0 }}>
      <div style={{ maxWidth: '85%', marginLeft: 'auto', marginRight: 'auto' }}>
        <Container fluid>
          <Row>
            <Col md={6}>
              {new Date().getFullYear()}
              {' '}
              © Skote.
            </Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                Design & Develop by Themesbrand
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
